<template>
  <el-dialog title="点击广告免费下载" :visible.sync="visible" @close="closeDialog" class="table">
    <span class="activityName">{{activityName}}</span>
    <img class="img" @click="clickAd" :src="imgUrl"  alt />
  </el-dialog>
</template>

<script>
import {queryAdInfo} from '../../../api/ads'
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getAdDatas();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      imgUrl:'',
      linkUrl:'',
      activityName:''
    };
  },
  methods: {
    async getAdDatas() {
     // 获取广告数据
      let dataform = {
        adPositionId: 4,
      };
      let res = await queryAdInfo(dataform);
      console.log("res",res);
      if(res!=null){
        this.imgUrl=res.imageUrl;
        this.linkUrl=res.link;
        this.activityName=res.name;
      }
    },
    clickAd(){
      //跳转到广告地址。
      window.open(this.linkUrl);
      this.closeDialog();//关闭弹框
      this.$emit("onclickAd") //通过$emit触发父组件 显示下载地址
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

  .img{
    width: 100%;
    height: 100%;
  }

  .activityName{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    margin-bottom: 5px;
  }
</style>
