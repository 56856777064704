<template>
  <div v-loading="tableLoading" >
    <el-table :data="obj.datalists" class="table">
      <el-table-column type="index" width="40"></el-table-column>
      <el-table-column label="素材ID" prop="recCode" width="140" class="font-bold"></el-table-column>
      <el-table-column label="图片" width="240">
        <template slot-scope="scope">
          <img :src="scope.row.icon" alt  height="80px"/>
        </template>
      </el-table-column>
      <el-table-column label="素材网站" prop="webType" width="140" class="font-bold"></el-table-column>
      <el-table-column label="格式" prop="formatName" width="60" class="font-bold"></el-table-column>
      <el-table-column label="下载时间" prop="buyTime" class="font-gray"></el-table-column>
      <el-table-column label="操作状态">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="download(scope.row)">重新下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="obj.total"
            v-if="obj.total > 10"
    ></el-pagination>
  </div>
</template>

<script>
  import {reDownload} from '../../../api/downLoad'
  export default {
    props: {
      obj:{
        type:Object
      },
      // 带有默认值的对象
      data: {
        type: Array
      }
    },
    computed: {
      tableData() {
        return this.data;
      }
    },
    data() {
      return {
        currentPage: 1,
        pageSize: 10,
        tableLoading: false
      };
    },
    methods: {
      download(item) {
        this.tableLoading = true;
        let buydata = {
          imageID:item.recCode,
          webType:item.webType,
          format:item.format
        }
        reDownload(buydata).then((result) => {
          console.log(JSON.stringify(result));
          this.tableLoading = false;
          window.open(result.downloadUrl,"_self");
        }).catch((error) => {
          console.log(JSON.stringify(error));
          this.tableLoading = false;
        })
      },
      handleSizeChange(val) {

        this.pageSize = val;
        this.currentPage = 1;
        this.$emit("sizeChange", val);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.$emit("pageChange", val);
      }
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>


</style>