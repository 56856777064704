/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'

/**
 * 获取广告信息
 * @param params
 * @returns {*|Promise|Promise<unknown>|undefined}
 */
export const queryAdInfo = (params) => {
    return request({
        url: serviceInterface.ads.queryAdInfo,
        params: params
    })
}
